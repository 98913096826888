import type {
    AbstractCategoryInterface,
    Include,
    Product,
    ProductInclude,
    RelationData,
} from '~/composables/types/api/searchDiscover/product';

import {
    EIncludeTypes,
} from '~/composables/types/api/searchDiscover/product';

const microFrontendComponentKey = ref(0);

const getAbstractProducts = <P extends {
    type: string
}>(products: P[] | undefined): P[] => (products ? products.filter((product) => product.type === 'abstract-products') : []);

const getConcreteProducts = <P extends {
    type: string
}>(products: P[]): P[] => (products ? products.filter((product) => product.type === 'concrete-products') : []);

const getRelationIncludes = (includes: Include[]): Include[] => (includes ?
    includes.filter(
        (include) => include.type !== EIncludeTypes.ABSTRACT_PRODUCTS
    ) :
    []);

const filterIncludes = <I extends Include>(includes: I[], filter: RelationData): I[] => includes.filter(
    (include) => include.type === filter.type && include.id === filter.id
);

const includesParser = (includes: Include[], filters: RelationData[]): Product => {
    const productData = {} as Product;

    filters.map((relationFilter: RelationData) => {
        const filteredData = filterIncludes(includes, relationFilter)[0]?.attributes;

        if (!filteredData) {
            return;
        }

        switch (relationFilter.type) {
            case EIncludeTypes.CATEGORY_NODES:
                if (!productData.categoryNodes) {
                    productData.categoryNodes = [];
                }

                productData.categoryNodes.push(filteredData);

                break;

            case EIncludeTypes.PRODUCT_REVIEWS:
                productData.reviews ?
                    productData.reviews.push(filteredData) :
                    productData.reviews = [filteredData];

                break;

            case EIncludeTypes.ABSTRACT_PRODUCT_PRICES:
                productData.price = filteredData.price;
                productData.prices = filteredData.prices;

                break;

            case EIncludeTypes.ABSTRACT_PRODUCT_RELATIONS:
                productData.abstractProductRelations = filteredData.abstractProductRelations;

                break;

            case EIncludeTypes.CONCRETE_PRODUCT_IMAGE_SETS:
                if (!productData.concreteImageSets) {
                    productData.concreteImageSets = {};
                }

                productData.concreteImageSets[relationFilter.id] = filteredData.imageSets[0]?.images;
                break;

            case EIncludeTypes.CONCRETE_PRODUCT_PRICES:
                if (!productData.concreteProductPrices) {
                    productData.concreteProductPrices = {};
                }

                productData.concreteProductPrices[relationFilter.id] = filteredData.prices;
                break;

            case EIncludeTypes.PRODUCT_LABELS:
                if (!productData.labels) {
                    productData.labels = [];
                }

                productData.labels.push({
                    value: filteredData.name,
                    frontEndReference: filteredData.frontEndReference,
                });

                break;

            case EIncludeTypes.ABSTRACT_PRODUCT_AVAILABILITIES:
            case EIncludeTypes.CONCRETE_PRODUCT_AVAILABILITIES:
                productData.stock = filteredData.isNeverOutOfStock ?
                    Infinity :
                    filteredData.quantity;

                if (!productData.concreteProductAvailabilities) {
                    productData.concreteProductAvailabilities = [];
                }

                productData.concreteProductAvailabilities.push({...filteredData, id: relationFilter.id, type: relationFilter.type });
                break;

            case EIncludeTypes.CONCRETE_PRODUCTS:
                if (!productData.concreteProducts) {
                    productData.concreteProducts = [];
                }

                productData.concreteProducts.push(filteredData);
                break;
            default:
                break;
        }
    });

    return productData;
};

const extendRelationsManually = (relationIncludes: Include<any, any>[], productRelations: any) => {
    const extendedRelations = {
        'concrete-product-image-sets': {
            data: relationIncludes
                .filter((obj: Include) => obj.type === EIncludeTypes.CONCRETE_PRODUCT_IMAGE_SETS)
                .map((obj) => ({
                    id: obj.id,
                    type: obj.type
                }))
        }
    };

    return {
        ...productRelations,
        ...extendedRelations
    };
};

const relationsParser = (
    product: Product,
    relationIncludes: Include <any, any>[] = [],
    productRelations: any = {},
): Product => {
    const relations = extendRelationsManually(relationIncludes, productRelations);

    for (const option in relations) {
        if (option) {
            const relation = relations[option].data;
            product = {
                ...product,
                ...includesParser(relationIncludes, relation),
            };
        }
    }

    return product;
};

const pricesParser = (product: Product) => {
    if (product.prices) {
        product.prices.pricesObject = {};

        for (const price in product.prices.prices) {
            // @ts-ignore
            product.prices.pricesObject[price] = {
                grossAmount: product.prices.prices[price],
                currency: product.prices.currency,
                intersportPriceType: product.prices?.price_data_by_price_type?.[price]?.intersportPriceType ?? null,
            };
        }
    }

    return product;
};

const productsParser = (
    products: ProductInclude[],
    productsRelations: Include[] = [],
): Product[] => products.map(
    (productInclude: ProductInclude): Product => {
        const product: Product = {
            type: productInclude.type,
            ...productInclude.attributes
        };

        return {
            ...product,
            ...relationsParser(product, productsRelations, productInclude.relationships),
            ...pricesParser(product),
        };
    },
);

const parseCategoryNodes = (nodes: any[]): AbstractCategoryInterface[] => nodes.map((x: any) => ({
    id: x.nodeId,
    name: x.name,
    active: x.isActive,
    children: parseCategoryNodes(x.children),
    parents: parseCategoryNodes(x.parents),
    url: x.url.replace('/de/gesamtsortiment', ''),
}));

const setComponentKey = (count: number) => {
    if ((microFrontendComponentKey.value === 0 && count > 0) ||
        (microFrontendComponentKey.value > 0 && count === 0)) {
        microFrontendComponentKey.value = count;
    }
};

export default function() {
    return {
        getAbstractProducts,
        getConcreteProducts,
        getRelationIncludes,
        productsParser,
        parseCategoryNodes,
        setComponentKey,
        microFrontendComponentKey,
    };
}
