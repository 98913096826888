<template>
    <div :class="{'matrix-top-container': true, 'matrix-top-container-line': isMatrixModal}">
        <div class="matrix-top-header-product-info">
            <MoleculeMatrixProductInfo
                :is-matrix-modal="isMatrixModal"
                :model-code="modelCode"
                :brand-id="brandId"
                :catalog-id="catalogId"
                :model-name="modelName"
                :brand="brandName"
                :labels="labels"
                :sku="sku"
                :image-url="image"
                :product-abstract-relations="productAbstractRelations"
                :pg-fedas4-name="pgFedas4Name"
                :pg-fedas4="pgFedas4"
                :hgg="hgg"
                :pg-fedas-name="pgFedasName"
                :product-abstract-id="productAbstractId"
                :product-type="productType"
                :customer-group-access="customerGroupAccess"
                :matrix-disabled="matrixDisabled"
                @close:close-matrix="emit('close:closeMatrix')"
            />
        </div>
        <MoleculeMatrixSupplierSelection
            :supplier-index='supplierIndex'
            :suppliers='suppliers'
            @update:supplier-index="emit('update:supplierIndex', $event)"
        />
        <MoleculeMatrixNetPrices
            class="matrix-net-prices"
            :model-code="modelCode"
            :brand-id="brandId"
            :catalog-id="catalogId"
            :model-name="modelName"
            :supplier-is-intersport="supplierIsIntersport"
            :concretes="concretes"
            :sections="sections"
            @update:net-prices-changed="updateNetPricesChanged"
        />
    </div>
</template>
<script setup lang="ts">
import type { ConcreteProductBySizeAndColor, MatrixSupplier } from '~/composables/types/api/searchDiscover/getMatrix';
import type { ProductAbstractRelations } from '~/composables/types/api/searchDiscover/catalogSearch';
import type { MatrixHeaderSection } from '~/composables/types/matrix/useMatrixContentDataTypes';

defineProps<{
    isMatrixModal: boolean,
    modelName: string,
    modelCode: string,
    brandId: string,
    catalogId: string,
    brandName: string,
    supplierIsIntersport: boolean,
    labels: string[],
    image: string,
    sku: string,
    supplierIndex: number,
    suppliers: MatrixSupplier[],
    concretes: ConcreteProductBySizeAndColor,
    sections: MatrixHeaderSection[] | null,
    productAbstractRelations: ProductAbstractRelations | null,
    pgFedas4Name: string | null,
    pgFedas4: string,
    hgg: string,
    pgFedasName: string,
    productAbstractId: string,
    productType: string,
    customerGroupAccess: boolean,
    matrixDisabled: boolean,
}>();

const emit = defineEmits<{
    (e: 'update:netPricesChanged', changed: boolean): void,
    (e: 'update:supplierIndex', index: number): void,
    (e: 'close:closeMatrix'): void,
}>();

const updateNetPricesChanged = (changed: boolean) => {
    emit('update:netPricesChanged', changed);
};

</script>
<style scoped lang="scss">
.matrix-top-container {
    height: rem(102);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.matrix-top-container-line {
    @include helper-border-b($setting-color-gray-2, rem(1), solid);
}

.matrix-top-header-product-info {
    flex-grow: 10;
}

.matrix-net-prices {
    margin-left: auto;
}
</style>
